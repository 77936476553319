import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import michaelpalmer from "../images/palmomedia-webentwicklung-kontakt-michael-palmer.jpg";
import xing from "../images/icon_xing.png";
import mail from "../images/icon_mail.png";
import linkedin from "../images/icon_in.png";
import twitter from "../images/icon_twitter.png";
import x from "../../static/twitter-x-logo.png";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Kontakt & Anfragen" />
      <h1>Kontakt</h1>
      <p>
        Rund um die IT sagen wir steht's:
        <br />
        <i>Es gibt keine dummen Fragen; nur dumme Antworten!</i>
      </p>
      <p>Über die folgenden Kanäle sind wir jederzeit für Sie erreichbar.</p>
      <h4>An das Team per E-Mail</h4>
      <p>
        <img src={mail} width="30" alt="Michael Palmer E-Mail" />
        &nbsp;&nbsp;&nbsp;&nbsp;E-Mail:{" "}
        <a href="mailto:hallo@palmomedia.de" target="_blank" title="E-Mail">
          hallo[at]palmomedia.de
        </a>
      </p>

      <h4>An den Inhaber persönlich</h4>
      <div className="row">
        <div className="col-sm-6">
          <img
            src={michaelpalmer}
            title="Michael Palmer"
            alt="Michael Palmer"
            className="images"
          />
        </div>{" "}
        <div className="col-sm-6">
          <br />
          <p>
            <img
              src={x}
              width="30"
              alt="Michael Palmer auf X ehemals Twitter"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;X:{" "}
            <a
              href="https://x.com/mitchobrian"
              rel="noreferrer"
              target="_blank"
              title="X ehemals Twitter"
            >
              @mitchobbian
            </a>
          </p>
          <p>
            <img src={xing} width="30" alt="Michael Palmer Xing" />
            &nbsp;&nbsp;&nbsp;&nbsp;Xing:{" "}
            <a
              href="https://www.xing.com/profile/Michael_Palmer5/cv"
              rel="noreferrer"
              target="_blank"
              title="Xing"
            >
              Michael Palmer (Xing)
            </a>
          </p>
          <p>
            <img src={linkedin} width="30" alt="Michael Palmer LinkedIn" />
            &nbsp;&nbsp;&nbsp;&nbsp;LinkedIn:{" "}
            <a
              href="https://www.linkedin.com/in/michael-palmer-b944b732/"
              rel="noreferrer"
              target="_blank"
              title="LinkedIn"
            >
              Michael Palmer (IN)
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
